import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

export interface ToolbarOptionEntry {
  name: string;
  icon: string;
  state: boolean;
  badge: number | string;
  order: number;
}

@Module({ namespaced: true })
export default class ToolbarOption extends VuexModule {
  enabledToolbarOptions: ToolbarOptionEntry[] = [];
  telephonyActive = false;

  @Action
  setTelephonyActive(active: boolean) {
    if (active) {
      this.context.commit('setAllToolbarStateFalse');
    }

    try {
      window.chrome.webview.postMessage({
        eventName: 'setWidth',
        width: 600
      });
    } catch (e) {
      console.log('not in standalone toolbar.');
    }

    this.context.commit('changeTelephonyActive', active);
  }

  @Mutation
  changeTelephonyActive(active: boolean) {
    this.telephonyActive = active;
  }

  @Mutation
  setAllToolbarStateFalse() {
    this.enabledToolbarOptions.forEach((opt) => {
      opt.state = false;
    });
  }

  @Mutation
  addToolbarOption(toolbarOption: ToolbarOptionEntry) {
    this.enabledToolbarOptions.push(toolbarOption);
    this.enabledToolbarOptions.sort((a, b) => {
      if (a.order > b.order) {
        return 1;
      } else if (a.order < b.order) {
        return -1;
      } else return 0;
    });
  }

  @Mutation
  updateState(toolbarOption: ToolbarOptionEntry) {
    this.enabledToolbarOptions.map((value) => {
      if (value.name === toolbarOption.name) {
        value = toolbarOption;
      } else {
        // TODO - lets see if we need mutlitple active at a time
        value.state = false;
      }
    });
  }

  @Action
  pushToolbarOption(toolbarOption: ToolbarOptionEntry[]) {
    toolbarOption.map((value) => {
      const state = value.state;

      value.state = false;

      this.context.commit('addToolbarOption', value);

      if (state) {
        this.context.dispatch('changeState', value);
      }
    });
  }

  @Action
  public changeState(toolbarOption: ToolbarOptionEntry) {
    this.context.commit('changeTelephonyActive', false);
    toolbarOption.state = !toolbarOption.state;

    this.context.commit('updateState', toolbarOption);

    const activeOptions = this.enabledToolbarOptions.filter((value) => {
      return value.state;
    });

    try {
      if (activeOptions.length > 0) {
        this.context.dispatch('setBadge', {
          name: toolbarOption.name,
          badge: ''
        });

        window.chrome.webview.postMessage({
          eventName: 'setWidth',
          width: 600
        });
      } else {
        // window.chrome.webview.postMessage({
        //   eventName: 'setWidth',
        //   width: 70
        // });
      }
    } catch (e) {
      console.log('');
    }
  }

  @Action
  public async changeStateByName(name: string) {
    const toolbarOption = this.enabledToolbarOptions.find((value) => {
      return value.name === name;
    });

    if (toolbarOption) {
      await this.context.dispatch('changeState', toolbarOption);
    }
  }

  @Mutation
  public changeBadge(event: { name: string; badge: number | string }) {
    const found = this.enabledToolbarOptions.find((value) => {
      return value.name === event.name;
    });
    if (found && (!found.state || !event.badge)) {
      found.badge = event.badge;
    }
  }

  @Action
  public async setBadge(event: { name: string; badge: number | string }) {
    this.context.commit('changeBadge', event);
  }

  @Action
  public setIcon(event: { name: string; icon: string }) {
    this.context.commit('changeIcon', event);
  }

  @Mutation
  public changeIcon(event: { name: string; icon: string }) {
    const found = this.enabledToolbarOptions.find((value) => {
      return value.name === event.name;
    });
    if (found) {
      found.icon = event.icon;
    }
  }
}
