import axios from 'axios';

export class GoogleSentimentService {
  private endpoint =
    'https://language.googleapis.com/v1beta2/documents:analyzeSentiment?key=AIzaSyC7MBuyIgLip-m0h_mrKU0YOgidMXNb4P8';

  async getSentiment(payload: {
    text: string;
    language: string;
  }): Promise<{
    score: number;
    magnitude: number;
  }> {
    const res = await axios.post<{
      documentSentiment: {
        score: number;
        magnitude: number;
      };
    }>(this.endpoint, {
      document: {
        language: payload.language,
        type: 'PLAIN_TEXT',
        content: payload.text
      }
    });

    return res.data.documentSentiment;
  }
}
