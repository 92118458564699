export default class StringHelperService {
  convertToString(input: string) {
    if (input) {
      if (typeof input === 'string') {
        return input;
      }

      return String(input);
    }
    return '';
  }

  // convert string to words
  toWords(input: string) {
    input = this.convertToString(input);

    const regex = /[A-Z\xC0-\xD6\xD8-\xDE]?[a-z\xDF-\xF6\xF8-\xFF]+|[A-Z\xC0-\xD6\xD8-\xDE]+(?![a-z\xDF-\xF6\xF8-\xFF])|\d+/g;

    return input.match(regex);
  }

  // convert the input array to camel case
  toCamelCase(inputArray: Array<string>) {
    let result = '';

    for (let i = 0, len = inputArray.length; i < len; i++) {
      const currentStr = inputArray[i];

      let tempStr = currentStr.toLowerCase();

      if (i != 0) {
        // convert first letter to upper case (the word is in lowercase)
        tempStr = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);
      }

      result += tempStr;
    }

    return result;
  }

  toDisplayCase(input: string) {
    const inputArray = this.toWords(input) as Array<string>;
    const result = new Array<string>();

    for (let i = 0, len = inputArray.length; i < len; i++) {
      const currentStr = inputArray[i];

      let tempStr = currentStr.toLowerCase();

      tempStr = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);
      result.push(tempStr);
    }

    return result.join(' ');
  }
}
