


























































































import { namespace } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ToolbarOptionEntry } from '@/store/modules/ToolbarOption';
import Transcription from '@/components/Transcription.vue';
import NextBestAction from '@/components/NextBestAction.vue';
import Guidance from '@/components/Guidance.vue';
import ChatView from '@/components/chat/ChatView.vue';
import ReplayAction from '@/components/ReplayAction.vue';
import Knowledge from '@/components/Knowledge.vue';
import AutoNotes from '@/components/AutoNotes.vue';
import CustomerInfo from '@/components/CustomerInfo.vue';
import TwilioTelephony from '@/components/TwilioTelephony.vue';
import Promises from '@/components/Promises.vue';
import NotificationPromises from '@/components/NotificationPromises.vue';

const toolbarOption = namespace('ToolbarOption');

@Component({
  components: {
    NotificationPromises,
    Promises,
    TwilioTelephony,
    CustomerInfo,
    AutoNotes,
    Knowledge,
    ReplayAction,
    NextBestAction,
    Transcription,
    Guidance,
    ChatView
  }
})
export default class ContentDrawer extends Vue {
  @toolbarOption.State
  public enabledToolbarOptions!: Array<ToolbarOptionEntry>;

  private wait = false;

  get activeToolbarOption(): ToolbarOptionEntry | undefined {
    this.wait = true;
    setTimeout(() => {
      this.wait = false;
    }, 500);
    return this.enabledToolbarOptions.find((value) => {
      return value.state;
    });
  }
}
