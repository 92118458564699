



































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AlertViewType } from '@/store/modules/Messages';

const messages = namespace('Messages');
@Component({})
export default class PopupSnackbar extends Vue {
  @messages.State
  popupEvent!: { message: string; alertType: AlertViewType; color: string; icon: string };

  @messages.Action
  private setPopupHtml!: (message: string) => void;

  private show = false;

  private currentPopup = { message: '', alertType: 0, color: '', icon: '' };

  private dismiss() {
    this.currentPopup = { message: '', alertType: 0, color: '', icon: '' };
    this.show = false;
  }

  @Watch('popupEvent')
  popUpChanged(popup: { message: string; alertType: AlertViewType; color: string; icon: string }) {
    console.log('Change in popup', popup);
    this.currentPopup = popup;
    this.show = true;
  }
}
