import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: {
          base: '#ff6a13', //Brand Orange
          lighten1: '#fff0e7' //Brand Seashell
        },
        secondary: {
          base: '#d40f7d', //Brand Magenta
          lighten1: '#fce8f1' //Brand Lavender
        },
        whitegray: '#f8f9fc',
        warmgray: '#f1f2f4',
        frostgray: '#e9ecf4',
        palegray: '#d7dae3',
        silver: '#bfbfd0',
        mediumgray: '#abaebc',
        coolgray: '#8b8ba0',
        sonic: '#6d6f79',
        onyx: '#424242',
        jet: '#333333',
        brightblue: '#4da1ff',
        buttons: '#d40f7d',
        activeIcons: '#ff6a13',
        toolbar: '#333333'
      }
    }
  }
});
