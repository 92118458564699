






































import Vue from 'vue';
import { Component, Prop, Watch, Ref } from 'vue-property-decorator';
import QuestionChoicesElement from '@/components/chat/FormElements/QuestionChoicesElement.vue';
import ParagraphElement from '@/components/chat/FormElements/ParagraphElement.vue';
import QuestionYesNoElement from '@/components/chat/FormElements/QuestionYesNoElement.vue';
import { namespace } from 'vuex-class';
import Messages from '@/store/modules/Messages';
import { InteractPageHeader, InteractPageSection, InteractSectionChoice } from '@/types';
import QuestionInputElement from '@/components/chat/FormElements/QuestionInputElement.vue';
import QuestionUploadPhotosElement from '@/components/chat/FormElements/QuestionUploadPhotosElement.vue';
import RatingsElement from '@/components/chat/FormElements/RatingsElement.vue';
import CollectionDisplayElement from '@/components/chat/FormElements/CollectionDisplayElement.vue';

const messages = namespace('Messages');

@Component({
  components: {
    QuestionChoicesElement,
    ParagraphElement,
    QuestionYesNoElement,
    QuestionInputElement,
    QuestionUploadPhotosElement,
    RatingsElement,
    CollectionDisplayElement
  }
})
export default class FormResponse extends Vue {
  @Prop({ required: true }) readonly pageContent!: Array<InteractPageSection>;
  @Prop({ required: true }) readonly header!: InteractPageHeader;
  @Prop({ required: true }) readonly index!: number;
  @Ref('form') readonly form!: HTMLFormElement;

  @messages.State
  private messages!: Array<Messages>;

  @messages.State
  private loading!: boolean;

  @messages.Action
  private continueFlow!: (inputParameters: any) => void;

  @messages.Action
  private setLoading!: (status: boolean) => void;

  private readonly componentNameTable = {
    QUESTION_CHOICES_ELEMENT: 'QuestionChoicesElement',
    PARAGRAPH: 'ParagraphElement',
    QUESTION_YES_NO_ELEMENT: 'QuestionYesNoElement',
    QUESTION_INPUT_ELEMENT: 'QuestionInputElement',
    QUESTION_UPLOAD_PHOTOS_ELEMENT: 'QuestionUploadPhotosElement',
    RATING_ELEMENT: 'RatingsElement',
    COLLECTION_DISPLAY_FORM_ELEMENT: 'CollectionDisplayElement'
  };

  private buttonsDisabled = false;
  private valid = true;

  private inputParameters: any = {};

  private updateInputParameters(first: string, second: string) {
    this.inputParameters[first] = second;
    console.log('Updated Input Parameters:', this.inputParameters);
  }

  private submit() {
    this.form.validate();
    if (this.valid) {
      this.setLoading(true);
      this.continueFlow(this.inputParameters);
      this.buttonsDisabled = true;
    } else {
      console.log('Required fields left empty!');
    }
  }

  get showSubmit(): boolean {
    let show = true;
    this.pageContent.forEach((section) => {
      section.sectionChoices.forEach((choice) => {
        if (
          choice.clickToContinueMatrixLayout ||
          choice.clickToContinue ||
          (choice.collectionItem && section.selectionType === 'SINGLE')
        ) {
          // if(choice.)
          show = false;
        }
      });
    });
    return show;
  }

  mounted() {
    console.log('pageContent:', this.pageContent);
  }

  @Watch('messages')
  messagesChanged() {
    this.buttonsDisabled = true;
  }
}
