// store/modules/Messages.ts
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { InteractAIAPI } from '@/api/InteractAIAPI';
import { Feedback, Message } from '@/types';
import InteractChatConfig from '@/store/modules/InteractChatConfig';
import { AxiosResponse } from 'axios';
import QuiqAPI from '@/api/QuiqAPI';
import twilioService from '@/services/twilio-realtime-speech.service.ts';
import { NextBestActionEntry } from '@/store/modules/NextBestAction';

import store from '@/store/index';
import { SpeechEvent } from '@/services/types/speech';
import { TriggerIntentFlow } from '@/services/interact/types/intent/intent-request';

export enum AlertViewType {
  INFO,
  WARN
}

export interface AlertEvent {
  action: 'popup' | 'nba';
  text?: string;
  html?: string;
  name?: string;
}

export interface TriggerEvent {
  eventType:
    | 'trigger_hidden'
    | 'show_response_only'
    | 'trigger_visible'
    | 'next_best_action'
    | 'popup'
    | 'nba'
    | 'prepare_message'
    | 'prepare_message_template';
  text: string;
  name?: string;
}

export interface NotificationObj {
  message: string;
  icon?: string;
  color?: string;
}

@Module({ namespaced: true })
class Messages extends VuexModule {
  public loading = false;
  public interactApi: InteractAIAPI = new InteractAIAPI(InteractChatConfig.state.config);
  public quiqApi: QuiqAPI = new QuiqAPI();
  public messages: Array<Message> = [];
  public stackedMessages: Array<Message> = [];
  private feedback: Array<Feedback> = [];
  private lastIntent = '';
  private messageMode = 'Interact';
  private lastFlowTitle = '';

  private notifications: Array<NotificationObj> = [];

  public previousNextBestActions: Array<NextBestActionEntry> = [];
  public nextBestActionEntries: Array<NextBestActionEntry> = [];
  public popupEvent: { message: string; alertType: AlertViewType } = {
    message: '',
    alertType: AlertViewType.INFO
  };

  public callerId = '';

  @Action
  addNotification(notification: NotificationObj) {
    this.context.commit('unshiftNotification', notification);
  }

  @Mutation
  unshiftNotification(notification: NotificationObj) {
    this.notifications.unshift(notification);
  }

  @Action
  clearNotifications() {
    this.context.commit('emptyNotifications');
  }

  @Mutation
  emptyNotifications() {
    this.notifications = [];
  }

  @Mutation
  private updateLastFlowTitle(title: string) {
    this.lastFlowTitle = title;
  }

  @Action
  public setLoading(status: boolean) {
    this.context.commit('updateLoading', status);
  }

  @Mutation
  private updateLoading(status: boolean) {
    this.loading = status;
  }

  @Action
  public setCallerId(callerId: string) {
    this.context.commit('updateCallerId', callerId);
  }

  @Mutation
  private pushPreviousNextBestAction(nba: NextBestActionEntry) {
    this.previousNextBestActions.push(nba);
  }

  @Mutation
  private updateCallerId(callerId: string) {
    this.callerId = callerId;
  }

  @Action
  private handleTriggerEvent(event: TriggerEvent) {
    switch (event.eventType) {
      case 'show_response_only':
        this.context.dispatch('addBotResponseOnly', event.text);
        break;
      case 'trigger_hidden':
        this.context.dispatch('triggerHiddenInput', event.text);
        break;
      case 'trigger_visible':
        this.context.dispatch('triggerVisibleInput', event.text);
        break;
      case 'next_best_action':
        this.context.dispatch('addNextBestActionEntry', {
          name: event.name,
          questionText: event.text
        });
        break;

      case 'nba':
        this.context.dispatch('addNextBestActionEntry', {
          name: event.name,
          questionText: event.text
        });
        break;

      case 'popup':
        this.context.commit('changePopupHtml', {
          message: event.text,
          alertType: AlertViewType.INFO
        });

        break;

      case 'prepare_message':
        store.dispatch('QuiqEvents/prepareMessage', event.text);
        break;
      case 'prepare_message_template':
        store.dispatch('QuiqEvents/prepareMessageTemplate', event.text);
        break;

      default:
        console.warn('Unknown event type received!', {
          eventData: event
        });
    }
  }

  @Action
  private handleAlertEvents(event: SpeechEvent) {
    switch (event.name) {
      case 'popup':
        this.context.commit('changePopupHtml', {
          message: event.message,
          color: event.color,
          icon: event.icon,
          alertType: AlertViewType.INFO
        });
        break;

      case 'alert':
        // eslint-disable-next-line no-case-declarations
        const nba: NextBestActionEntry = {
          questionText: event.message,
          name: event.intent
        };
        this.context.dispatch('addNextBestActionEntry', nba);
        break;

      default:
        break;
    }
  }

  @Action
  async listenToAgentSession(sessionId: string) {
    store.state.InteractEvents.eventService.subscribe('/' + sessionId + '/alertEvents', (event: any) => {
      const message: AlertEvent = JSON.parse(event.body).data;
      console.log(message);
      // this.handleAlertEvents(message);
    });

    store.state.InteractEvents.eventService.subscribe('/' + sessionId + '/assistantEvents', (event: any) => {
      const message: TriggerEvent = JSON.parse(event.body).data;
      console.log(message);

      this.context.dispatch('handleTriggerEvent', message);
    });
  }

  @Action
  async subscribeToTwilioIntent() {
    twilioService.getSpeechEvents()?.subscribe(async (value) => {
      console.log('Speech Event from twilioIntent', value);
      switch (value.name) {
        case 'start':
          await this.context.dispatch('beginChat');
          break;

        case 'agent_joined':
          await this.context.dispatch('listenToAgentSession', value.sessionId);

          break;

        case 'intent':
          if (
            value.message &&
            value.intent?.toLowerCase().indexOf('fallback') == -1 &&
            value.intent?.toLowerCase().indexOf('default') == -1
          ) {
            // await this.context.dispatch('agentQuestion', value.message);

            const nba: NextBestActionEntry = {
              questionText: value.message,
              name: value.intent
            };
            await this.context.dispatch('addNextBestActionEntry', nba);

            /*

            const message: Message = {
              timestamp: new Date().getTime(),
              stringMessage: value.intent,
              source: 'USER',
              type: 'STANDARD'
            };
            await this.context.dispatch('addMessage', message);

             */
            break;
          }
      }
    });
  }

  @Action
  public setPopupHtml(popup: { message: string; alertType: AlertViewType }) {
    this.context.commit('changePopupHtml', popup);
  }

  @Mutation
  public changePopupHtml(popup: { message: string; alertType: AlertViewType; color: string; icon: string }) {
    this.popupEvent = popup;
  }

  @Action
  public initQuiqChat() {
    this.context.commit('setMessageMode', 'Quiq');
    this.context.commit('initializeQuiqChat');
  }

  @Mutation
  public initializeQuiqChat() {
    this.quiqApi.init().catch((e) => {
      throw e;
    });
  }

  @Action
  public postQuiqRegistration(form: object) {
    this.quiqApi.postRegistration(form).catch((e) => {
      throw e;
    });
  }

  @Mutation
  public setMessageMode(messageMode: string) {
    this.messageMode = messageMode;
  }

  @Action
  public addQuiqMessage(message: Message) {
    this.context.commit('appendMessage', message);
  }

  @Mutation
  public appendFeedback(feedback: Feedback) {
    feedback.intentName = this.lastIntent;
    this.feedback.push(feedback);
  }

  @Action
  public addFeedback(feedback: Feedback) {
    this.context.commit('appendFeedback', feedback);
  }

  @Mutation
  public removeLastMessage(): void {
    this.messages.pop();
  }

  @Mutation
  public appendMessage(message: Message): void {
    this.messages.push(message);
  }

  @Mutation
  public setLastIntent(lastIntent: string): void {
    this.lastIntent = lastIntent;
  }

  @Mutation
  public reset() {
    localStorage.removeItem('chatbot_userId');
    localStorage.removeItem('chatSessionId');
    this.messages = new Array<Message>();
    this.nextBestActionEntries = new Array<NextBestActionEntry>();
  }

  @Mutation
  public addMessageToStack(message: Message): void {
    this.stackedMessages.push(message);
  }

  @Mutation removeMessageFromStack(message: Message): void {
    this.stackedMessages = this.stackedMessages.filter((value) => {
      return value !== message;
    });
  }

  @Action
  public async addMessage(message: Message): Promise<void> {
    // if last message === BotThinking

    if (this.messages.length > 0 && this.messages[this.messages.length - 1].type === 'THINKING') {
      this.context.commit('addMessageToStack', message);
      return;
    }

    // then stack the message as interact has not yet responded

    this.context.commit('appendMessage', message);
    if ((message.source === 'USER' || message.source === 'BTN') && this.messageMode === 'Interact') {
      this.interactApi
        .sendMessage(message)
        .then((res) => {
          console.log(this);
          this.context.dispatch('handleResponse', res);
        })
        .catch((err) => {
          console.log(err);
          this.context.dispatch('handleResponse', err);
        });
    } else if (this.messageMode === 'Quiq') {
      this.quiqApi.postMessage(message.stringMessage!);
    }
  }

  @Action
  public continueFlow(inputParameters: any): void {
    this.interactApi
      .continueFlow(inputParameters, this.feedback)
      .then((res) => {
        this.context.dispatch('handleResponse', res);
      })
      .catch((err) => {
        this.context.dispatch('handleResponse', err);
      });
  }

  @Action
  public goToFlow(flowId: string): void {
    this.interactApi
      .goToFlow(flowId, this.feedback)
      .then((res) => {
        this.context.dispatch('handleResponse', res);
      })
      .catch((err) => {
        this.context.dispatch('handleResponse', err);
      });
  }

  @Action
  public invokeFlow(flowId: string): void {
    this.interactApi
      .goToFlow(flowId)
      .then((res) => {
        console.log('Invoked flow response:', res);
      })
      .catch((err) => {
        console.log('Invoked flow error:', err);
      });
  }

  @Action
  public returnToNlp(backToNlpText: string): void {
    this.interactApi
      .returnToNlp(backToNlpText, this.feedback)
      .then((res) => {
        if (res.data.html || backToNlpText !== 'receive_evaluation_from_client') {
          this.context.dispatch('handleResponse', res);
        }
      })
      .catch((err) => {
        this.context.dispatch('handleResponse', err);
      });
  }

  @Action
  public sendMessageWithVariables(data: { text: string; variables: any }) {
    this.interactApi
      .sendMessageWithVariables(data.text, data.variables)
      .then((res) => {
        console.log(this);
        this.context.dispatch('handleResponse', res);
      })
      .catch((err) => {
        console.log(err);
        this.context.dispatch('handleResponse', err);
      });
  }

  @Action
  public addFlowMessage(title: string) {
    const message: Message = {
      flowName: title,
      source: 'BOT',
      type: 'FLOW NAME',
      timestamp: new Date().getTime()
    };
    this.context.commit('appendMessage', message);
  }

  @Action
  public startChat(message: Message): void {
    if (message.flowName !== this.lastFlowTitle) {
      this.context.commit('updateLastFlowTitle', message.flowName);
      this.context.dispatch('addFlowMessage', message.flowName);
    }
    this.context.commit('appendMessage', message);
  }

  @Action
  public async beginChat(speechEvent?: SpeechEvent) {
    this.context.commit('reset');

    if (this.messageMode !== 'LiveChat') {
      try {
        const sessionName = speechEvent?.name === 'quiq' ? 'QuiqConversationId' : 'CallminerSessionId';
        await this.interactApi.init(InteractChatConfig.state.config, sessionName, speechEvent?.sessionId);

        const chatSessionId = store.state.InteractChatConfig.config.chatSessionId;

        store.state.InteractEvents.eventService.subscribe(
          '/' + chatSessionId + '/assistantEvents',
          (message: { body: string }) => {
            const parsedMessage = JSON.parse(message.body);
            const eventData = parsedMessage.data as TriggerEvent;

            this.context.dispatch('handleTriggerEvent', eventData);
          }
        );
      } catch (e) {
        console.log('Error creating session', e);
      }
    } else {
      //TODO - Init Serenova Live Chat
    }
  }

  @Action
  public async beginUAssistSession(callId: string) {
    this.context.commit('reset');
    try {
      const sessionName = 'UAssist';
      await this.interactApi.init(InteractChatConfig.state.config, sessionName, callId);

      const chatSessionId = store.state.InteractChatConfig.config.chatSessionId;

      store.state.InteractEvents.eventService.subscribe(
        '/' + chatSessionId + '/assistantEvents',
        (message: { body: string }) => {
          const parsedMessage = JSON.parse(message.body);
          const eventData = parsedMessage.data as TriggerEvent;

          this.context.dispatch('handleTriggerEvent', eventData);
        }
      );
    } catch (e) {
      console.log('Error creating session', e);
    }
  }

  @Action
  public async initBotId() {
    const botId = await this.interactApi.getRpaBodId();
    await store.dispatch('InteractChatConfig/updateBotId', botId);
  }

  @Action
  public uploadResource(resource: { file: File; id: string; headerId: string }) {
    console.log('Id in store', resource.id);
    this.interactApi
      .uploadResource(resource.file, resource.id, resource.headerId)
      .then(() => {
        console.log('Resource uploaded');
      })
      .catch((err) => {
        console.error(err);
      });
  }

  @Action
  public handleResponse(res: AxiosResponse<any> | any) {
    console.log('Data from interact', res);
    this.context.commit('updateLoading', false);
    if (!res.data) {
      console.error('Error with interact request / response');

      const message: Message = {
        source: 'BOT',
        timestamp: new Date().getTime(),
        type: res.type || 'STANDARD',
        html: 'Please try another action.'
      };

      this.context.dispatch('addMessage', message);

      return;
    }

    const message: Message = {
      source: 'BOT',
      timestamp: res.data.timestamp || new Date().getTime(),
      type: res.type || 'STANDARD'
    };
    if (res.data.nlpEngineResponse && res.data.nlpEngineResponse.vendorResponse.queryResult.intent.displayName) {
      const lastIntent = res.data.nlpEngineResponse.vendorResponse.queryResult.intent.displayName;
      this.context.commit('setLastIntent', lastIntent);
    }

    if (
      res.data.interactResponse &&
      res.data.interactResponse.flowInformation &&
      res.data.interactResponse.flowInformation.name
    ) {
      if (this.lastFlowTitle != res.data.interactResponse.flowInformation.name) {
        this.context.commit('updateLastFlowTitle', res.data.interactResponse.flowInformation.name);

        // added to avoid showing empty responses. - Tom
        if (res.data && res.data.html && res.data.html.indexOf('#noshow#') > -1) {
          return;
        }

        this.context.dispatch('addFlowMessage', res.data.interactResponse.flowInformation.name);
      }
      message.flowName = res.data.interactResponse.flowInformation.name;

      if (res.data.nlpEngineResponse && res.data.nlpEngineResponse.vendorResponse) {
        const nextBestActionEntry: NextBestActionEntry = {
          name: message.flowName,
          questionText: res.data.nlpEngineResponse.vendorResponse.queryResult.queryText
        };
        this.context.dispatch('addPreviousNextBestActionEntry', nextBestActionEntry);
      }
    }

    if (
      res.data.interactResponse &&
      res.data.interactResponse.elementResponse &&
      res.data.interactResponse.elementResponse.page &&
      res.data.interactResponse.elementResponse.page.pageNavigation.pageReferenceName
    ) {
      message.responseName = res.data.interactResponse.elementResponse.page.pageNavigation.pageReferenceName;
    }

    if (res.data && res.data.html) {
      message.html = res.data.html;
    } else if (res.data && res.data.interactResponse) {
      if (res.data.interactResponse.elementResponse) {
        message.pageContent = res.data.interactResponse.elementResponse.page.pageContent.contentSections;
        message.pageHeader = res.data.interactResponse.elementResponse.page.pageContent.contentHeader;
      } else {
        return;
      }
    } else {
      message.html = `<p>Sorry there was a problem</p>`;
    }

    this.context.commit('appendMessage', message);

    if (this.stackedMessages.length > 0) {
      const stackedMessage = this.stackedMessages[0];
      this.context.commit('removeMessageFromStack', stackedMessage);

      this.context.dispatch('addMessage', stackedMessage);
    }
  }

  @Mutation
  public appendNextBestActionEntry(entry: NextBestActionEntry): void {
    // only do it if we don´t have it already
    // I know this is not the right place

    const found = this.nextBestActionEntries.find((value) => {
      return value.name === entry.name;
    });

    if (!found) {
      this.nextBestActionEntries.push(entry);
    }
  }

  @Action
  public addPreviousNextBestActionEntry(entry: NextBestActionEntry): void {
    console.log('I am adding a next best action', entry);
    entry.name = entry.name?.substr(entry.name?.indexOf('_') + 1, entry.name?.length);

    // filter some entries
    if (entry.name?.toLocaleLowerCase().indexOf('fallback') != -1) {
      console.log('I am not adding this entry', entry.name);
      return;
    } else if (entry.name?.toLocaleLowerCase().indexOf('welcome') != -1) {
      console.log('I am not adding this entry', entry.name);
      return;
    } else if (entry.name?.toLocaleLowerCase().indexOf('-auto') != -1) {
      console.log('I am not adding this entry', entry.name);
      return;
    }

    const found = this.previousNextBestActions.find((value) => {
      const oldIntentName = value.name?.replaceAll('-', ' ').toLocaleLowerCase();
      const newIntentName = entry.name?.toLocaleLowerCase();
      if (oldIntentName != undefined && newIntentName != undefined) {
        return newIntentName.endsWith(oldIntentName);
      }

      return false;
    });

    if (!found) {
      entry.name = entry.name?.replaceAll('-', ' ');

      this.context.commit('pushPreviousNextBestAction', entry);

      store.dispatch('ToolbarOption/setBadge', {
        name: 'Next Best Action',
        badge: this.nextBestActionEntries.length
      });
    }
  }

  @Action
  public addNextBestActionEntry(entry: NextBestActionEntry): void {
    console.log('I am adding a next best action', entry);
    entry.name = entry.name?.substr(entry.name?.indexOf('_') + 1, entry.name?.length);

    // filter some entries
    if (entry.name?.toLocaleLowerCase().indexOf('fallback') != -1) {
      console.log('I am not adding this entry', entry.name);
      return;
    } else if (entry.name?.toLocaleLowerCase().indexOf('welcome') != -1) {
      console.log('I am not adding this entry', entry.name);
      return;
    } else if (entry.name?.toLocaleLowerCase().indexOf('-auto') != -1) {
      console.log('I am not adding this entry', entry.name);
      return;
    }

    const found = this.nextBestActionEntries.find((value) => {
      const oldIntentName = value.name?.replaceAll('-', ' ').toLocaleLowerCase();
      const newIntentName = entry.name?.toLocaleLowerCase();
      if (oldIntentName != undefined && newIntentName != undefined) {
        return newIntentName.endsWith(oldIntentName);
      }

      return false;
    });

    if (!found) {
      entry.name = entry.name?.replaceAll('-', ' ');

      this.context.commit('appendNextBestActionEntry', entry);

      store.dispatch('ToolbarOption/setBadge', {
        name: 'Next Best Action',
        badge: this.nextBestActionEntries.length
      });
    }
  }

  @Action
  public triggerReplayAction(entry: NextBestActionEntry): void {
    console.log('triggerReplayAction:', entry);

    if (entry.questionText) {
      const message: Message = {
        source: 'USER',
        timestamp: Date.now(),
        type: 'STANDARD',
        stringMessage: entry.questionText
      };

      this.context.dispatch('addMessage', message);
    } else if (entry.flowEvent) {
      this.context.dispatch('triggerHiddenIntentFlow', entry.flowEvent);
    }

    this.context.dispatch('triggerRemoveReplayAction', entry);
    this.context.dispatch('addPreviousNextBestActionEntry', entry);

    store.dispatch('ToolbarOption/setBadge', {
      name: 'Next Best Action',
      badge: 0
    });
    // TODO send message again
  }

  @Mutation
  public removeReplayAction(entry: NextBestActionEntry): void {
    this.nextBestActionEntries = this.nextBestActionEntries.filter((value) => {
      return value.name !== entry.name;
    });
  }

  @Action
  public triggerRemoveReplayAction(entry: NextBestActionEntry): void {
    console.log('triggerReplayAction:', entry);
    this.context.commit('removeReplayAction', entry);
  }

  @Action
  public addBotResponseOnly(botResponseText: string) {
    console.log(botResponseText);
    const message: Message = {
      source: 'BOT',
      timestamp: Date.now(),
      type: 'STANDARD',
      html: '<p>' + botResponseText + '</p>'
    };

    this.context.commit('appendMessage', message);
  }

  @Action
  public triggerHiddenInput(hiddenUserInput: string) {
    console.log(hiddenUserInput);

    const message: Message = {
      source: 'USER',
      timestamp: Date.now(),
      type: 'STANDARD',
      stringMessage: hiddenUserInput
    };

    this.interactApi
      .sendMessage(message)
      .then((res) => {
        console.log(this);
        this.context.dispatch('handleResponse', res);
      })
      .catch((err) => {
        console.log(err);
        this.context.dispatch('handleResponse', err);
      });
  }

  @Action
  public triggerHiddenIntentFlow(triggerRequest: TriggerIntentFlow) {
    this.interactApi.triggerIntentFlow(triggerRequest).then((res) => {
      this.context.dispatch('handleResponse', res);
    });
  }

  @Action
  public triggerVisibleInput(visibleUserInput: string, variableName?: string, variableValue?: string) {
    console.log(visibleUserInput);

    const message: Message = {
      source: 'USER',
      timestamp: Date.now(),
      type: 'STANDARD',
      stringMessage: visibleUserInput
    };

    this.context.dispatch('addMessage', message);
  }
}

export default Messages;
