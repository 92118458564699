

























import { NextBestActionEntry } from '@/store/modules/NextBestAction';
import { namespace } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Message } from '@/types';
import NotificationsBar from '@/components/NotificationsBar.vue';

const messages = namespace('Messages');

@Component({
  components: { NotificationsBar }
})
export default class PreviousFlows extends Vue {
  @messages.State
  public previousNextBestActions!: Array<NextBestActionEntry>;

  @messages.Action
  public triggerReplayAction!: (action: NextBestActionEntry) => void;

  get previousNextBestActionEntriesReversed() {
    return this.previousNextBestActions.reverse();
  }
}
