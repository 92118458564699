import axios from 'axios';
import { DemoConfigItem } from '@/services/demo-config.service';

export interface WatsonDiscoveryResponse {
  result: {
    results: Array<WatsonArticle>;
  };
}

export interface WatsonArticle {
  id: string;
  text: string;
  answer?: Array<string>;
  question: Array<string>;
  result_metadata: {
    confidence: number;
    score: number;
  };
  segment_metadata?: {
    id: string;
    parent_id: string;
    total_segments: number;
  };
  extracted_metadata: {
    author: Array<string>;
    title: string;
    file_type: string;
    filename: string;
  };
  footer?: Array<string>;
  viewed: boolean;
  fuzzyMatch?: number;
}

export class WatsonDiscoveryService {
  async query(text: string, demoConfigItem?: DemoConfigItem): Promise<Array<WatsonArticle>> {
    const watsonParams = {
      environmentId: 'ebc0f6e5-dd12-45d7-a252-8c2cffb71cb2',
      collectionId: '09684425-747c-4213-b54b-b7371c038e00',
      apikey: 'sLWD7vlqKthBEJv_m6EROyBV1_k1ELNzhNLLRo4kqBpB',
      serviceUrl: 'https://api.us-east.discovery.watson.cloud.ibm.com/instances/ca781a5c-64c5-4cb1-b84b-3f2f9fdb8a83',
      queryText: text
    };
    if (demoConfigItem) {
      demoConfigItem.configItems.map((value) => {
        switch (value.name) {
          case 'watson_environmentId':
            watsonParams.environmentId = value.value;
            break;
          case 'watson_collectionId':
            watsonParams.collectionId = value.value;
            break;
          case 'watson_url':
            watsonParams.serviceUrl = value.value;
            break;
          case 'watson_apiToken':
            watsonParams.apikey = value.value;
            break;
        }
      });
    }

    const res = await axios.post<WatsonDiscoveryResponse>(
      '//cognitive-services.jacadademos.com/watson-discovery/query',
      watsonParams
    );

    return res.data.result.results;
  }
}
