











import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import axios from 'axios';
import { DemoConfigItem } from '@/services/demo-config.service';

const DemoConfigNS = namespace('DemoConfig');

@Component({})
export default class SelectConfig extends Vue {
  apiEndpoint = 'https://newdemocenter.democenter.jacadademos.com/api/agent-assistant-configs';

  configs: Array<DemoConfigItem> = new Array<DemoConfigItem>();

  constructor() {
    super();
  }

  @DemoConfigNS.Action
  loadConfigById!: (configId: string) => void;

  async mounted() {
    const data = await axios.get<DemoConfigItem[]>(this.apiEndpoint);

    this.configs = data.data;

    //const configId = this.$route.params.configId || '12851';
    //this.loadConfigById(configId);
  }
}
