





































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { InteractPageSection, InteractSectionChoice } from '@/types';

const messages = namespace('Messages');

@Component({})
export default class QuestionChoicesElement extends Vue {
  @Prop({ required: true }) readonly section!: InteractPageSection;

  @messages.Action
  public continueFlow!: (inputParameters: any) => void;

  private clickedOnce = false;

  private selections: Array<string> = [];

  private defaultSelection = '';

  private rules: Array<any> = [(v: any) => !!(v + 1) || 'Required'];

  mounted() {
    this.section.sectionChoices.map((section) => {
      this.defaultSelection = section.choiceValue;
      this.$emit('updateInputParameters', this.section.id, section.choiceValue);
    });
  }

  private getClass(choice: InteractSectionChoice) {
    let ret = '';
    if (choice.referenceName.includes('ReplySuggestion')) {
      ret = 'my-1 px-6 rounded-lg v-btn--custom v-btn--reply';
    } else {
      ret = 'my-1 px-6 rounded-lg v-btn--custom';
    }

    return ret;
  }

  private getButtonColor(choice: InteractSectionChoice) {
    if (choice.clickToContinue?.selected) {
      return 'secondary';
    } else return 'primary';
  }

  private get getType() {
    let type = '';
    this.section.sectionChoices.forEach((choice) => {
      if (choice.clickToContinueMatrixLayout) {
        type = 'matrix';
      } else if (choice.collectionItem) {
        if (this.section.selectionType === 'SINGLE') {
          type = 'carousel';
        } else {
          type = 'checkbox';
        }
      } else if (choice.choiceOption) {
        if (this.section.selectionType === 'SINGLE') {
          type = 'radio';
        } else {
          type = 'checkbox';
        }
      } else if (choice.clickToContinue) {
        type = 'button';
      } else if (choice.selectFromList) {
        type = 'dropdown';
      }
    });
    return type;
  }

  private get isMatrix() {
    let isMatrix = false;
    this.section.sectionChoices.forEach((choice) => {
      if (choice.clickToContinueMatrixLayout) {
        isMatrix = true;
      }
    });
    return isMatrix;
  }

  private carouselChanged(index: number) {
    const first = this.section.id;
    const second = this.section.sectionChoices[index].id;
    this.updateInputParameters(first, second);
  }

  private clicked(first: string, second: string) {
    if (!this.clickedOnce) {
      const inputParameters: any = {};
      inputParameters[first] = second;

      this.continueFlow(inputParameters);
      this.clickedOnce = true;
    }
  }

  private async clickedToContinue(choice: InteractSectionChoice) {
    debugger;
    await this.$emit('updateInputParameters', choice.id, choice.clickToContinue?.id);
    if (choice.clickToContinue?.isMandatory) {
      await this.$emit('submit');
    } else {
      if (choice.clickToContinue) {
        choice.clickToContinue.selected = !choice.clickToContinue.selected;
        this.$forceUpdate();
      }
    }
  }

  private updateCheckInputParameters() {
    this.$emit('updateInputParameters', this.section.id, this.selections);
  }

  private updateInputParameters(first: string, second: string) {
    this.$emit('updateInputParameters', first, second);
  }

  // private updateInputParameters() {
  //   this.$emit('updateInputParameters', this.section.id, this.selection);
  // }
  //
  // mounted() {
  //   this.$emit('updateInputParameters', this.section.id, this.selection);
  // }
}
