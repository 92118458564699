










































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import StringHelperService from '@/services/string-helper.service';
import { TranscriptionMessageEntry } from '@/store/modules/Transcription';

const ToolbarOptionNS = namespace('ToolbarOption');
const TranscriptionNS = namespace('Transcription');

@Component({
  components: {}
})
export default class Promises extends Vue {
  loading = false;
  snackbar = false;
  snackbarText = '';
  stringHelperService = new StringHelperService();

  promises: Array<TranscriptionMessageEntry> = [];

  constructor() {
    super();
  }

  @ToolbarOptionNS.Action
  setBadge!: (event: { name: string; badge: number | string }) => void;

  @TranscriptionNS.State
  messages!: Array<TranscriptionMessageEntry>;

  @Watch('messages', {
    deep: true,
    immediate: true
  })
  transcriptChanged(messages: Array<TranscriptionMessageEntry>) {
    if (messages.length === 0) {
      return;
    }
    if (
      messages[0].from === 'agent' &&
      messages[0].message.indexOf('back') > -1 &&
      messages[0].message.indexOf('week') > -1
    ) {
      const found = this.promises.filter((value) => {
        return value.key === messages[0].key;
      });

      if (found) {
        this.promises.pop();
      }

      this.promises.push(messages[0]);

      this.setBadge({
        badge: this.promises.length,
        name: 'Promises'
      });
    }
  }
}
