import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AuthResponse } from '@/services/interact/LoginService';

@Module({ namespaced: true })
export default class InteractAuth extends VuexModule {
  private authResponse: AuthResponse | undefined;

  @Mutation
  setAuthResponse(authResponse: AuthResponse) {
    console.log(`User has roles: ${authResponse.roles}`);
    this.authResponse = authResponse;
  }

  @Action
  async updateAuthResponse(authResponse: AuthResponse) {
    this.context.commit('setAuthResponse', authResponse);
  }
}
