























import { Component, Prop, Vue } from 'vue-property-decorator';
import { NextBestActionEntry } from '@/store/modules/NextBestAction';
import { namespace } from 'vuex-class';
import { GuidanceEntry } from '@/store/modules/Guidance';

const GuidanceNS = namespace('Guidance');
const ToolbarNS = namespace('ToolbarOption');

@Component({})
// eslint-disable-next-line no-undef
export default class NextBestActionMessage extends Vue {
  @Prop({ required: true }) readonly message!: NextBestActionEntry;

  @GuidanceNS.Action
  public addAndStart!: (script: GuidanceEntry) => void;

  @ToolbarNS.Action
  public changeStateByName!: (name: string) => void;

  public scriptClicked(message: NextBestActionEntry) {
    if (message.script && message.name) {
      this.changeStateByName('Guidance');

      this.addAndStart({
        name: message.name,
        script: message.script
      });
    }
  }
}
