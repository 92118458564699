import { EventService } from '@/services/interact/EventService';
import { Subject } from 'rxjs';
import axios from 'axios';
import { DemoConfigItem, DemoConfigService } from '@/services/demo-config.service';

export class UAssistEventService {
  private connectorEndpoint = 'https://u-assist-integration.uniphoredemos.com/api';
  //private connectorEndpoint = 'http://localhost:3333/api';

  public async startListening(
    eventService: EventService,
    demoConfigItem: DemoConfigItem,
    rpaBotId: string
  ): Promise<Subject<any>> {
    const subject = new Subject<any>();

    await this.deregisterEventListener(rpaBotId);

    const uAssistUserName = DemoConfigService.getConfigItemByNameWithDefault(
      demoConfigItem,
      'uassist_username',
      'v0001'
    );
    const uAssistPassword = DemoConfigService.getConfigItemByNameWithDefault(
      demoConfigItem,
      'uassist_password',
      'Uniphore@123'
    );

    try {
      const accessToken = await this.login(uAssistUserName, uAssistPassword);
      await this.registerEventListener(demoConfigItem, accessToken, rpaBotId);
    } catch (e) {
      console.log('U-Assist authentication failed. Still going to continue');
    }


    eventService.subscribe('/' + rpaBotId + '/u-assist-events', (message) => {
      const body = JSON.parse(message.body);
      const eventData = JSON.parse(body.data) as any;
      console.log(eventData);

      subject.next(eventData);
    });

    return subject;
  }

  public async generateSummary(botId: string) {
    await axios.post(this.connectorEndpoint + '/trigger-event/generateSummary/' + encodeURIComponent(botId));
  }

  private async login(username: string, password: string): Promise<string> {
    const response = await axios.post(this.connectorEndpoint + '/auth/login', {
      username: username,
      password: password
    });

    return response.data['access_token'];
  }

  private async registerEventListener(demoConfigItem: DemoConfigItem, accessToken: string, rpaBotId: string) {
    const tenant = DemoConfigService.getConfigItemByNameWithDefault(demoConfigItem, 'interact_tenant', '');
    const applicationKey = DemoConfigService.getConfigItemByNameWithDefault(demoConfigItem, 'interact_app_key', '');
    const userId = DemoConfigService.getConfigItemByNameWithDefault(demoConfigItem, 'uassist_user_id', '');

    const response = await axios.post(this.connectorEndpoint + '/event/register', {
      userId: userId, // keycloak user
      // eslint-disable-next-line @typescript-eslint/camelcase
      access_token: accessToken,
      clientId: rpaBotId, // uuid to listen on events
      tenant: tenant,
      applicationKey: applicationKey,
      environmentName: 'Dev'
    });

    console.log(response);
  }

  private async deregisterEventListener(rpaBotId: string) {
    await axios.post(this.connectorEndpoint + '/event/de-register/' + rpaBotId);
  }
}

const uAssistEventService = new UAssistEventService();
export default uAssistEventService;
