






import { Component, Vue } from 'vue-property-decorator';
import Toolbar from '@/components/Toolbar.vue';
import { namespace } from 'vuex-class';

const DemoConfigNS = namespace('DemoConfig');

@Component({
  components: {
    Toolbar
  }
})
export default class Home extends Vue {
  @DemoConfigNS.Action
  loadConfigById!: (configId: string) => void;

  mounted() {
    this.loadConfigById('19554');
  }
}
