

















import Vue from 'vue';
import { Component, Prop, Ref } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as htmlToImage from 'html-to-image';
import { jsPDF } from 'jspdf';
import { Feedback, Message, MessageType } from '@/types';

const messages = namespace('Messages');
const interactConfig = namespace('InteractChatConfig');

interface BotHTML {
  outerHTML: string;
}

@Component({})
export default class BotResponse extends Vue {
  @Prop({ required: true }) readonly message!: Message;
  @Prop({ required: true }) readonly index!: number;
  @Ref('botHTML') readonly botHTML!: BotHTML;
  @messages.State
  public messages!: Array<Message>;
  @messages.Action
  public returnToNlp!: (backToNlpText: string) => void;
  @messages.Action
  public goToFlow!: (flowId: string) => void;
  @messages.Action
  public addFeedback!: (feedback: Feedback) => void;
  @interactConfig.Action
  public updateDrawerOpen!: (drawerOpen: boolean) => void;
  @interactConfig.Action
  public updateIframeSource!: (iframeSource: string) => void;

  private html = '';
  private showFeedback = false;
  private feedbackProvided = false;

  private clicked(e: any): void {
    if (e.target.matches('button.rich-ui-button')) {
      e.target.classList.add('selected');
      console.log('Clicked Event:', e.target.innerText, e.target.getAttribute('back-to-nlp-text'));
      if (e.target.getAttribute('back-to-nlp-text')) {
        this.returnToNlp(e.target.getAttribute('back-to-nlp-text'));
        e.target.parentNode.querySelectorAll('button.rich-ui-button').forEach((btn: Element) => {
          (btn as HTMLInputElement).disabled = true;
        });
      } else if (e.target.getAttribute('go-to-url')) {
        this.updateIframeSource(e.target.getAttribute('go-to-url'));
        this.updateDrawerOpen(true);
      } else if (e.target.getAttribute('go-to-flow')) {
        this.goToFlow(e.target.getAttribute('go-to-flow'));
      } else if (e.target.id === 'test-card') {
        this.downloadWithCSS();
      }
    }
  }

  public downloadWithCSS(): void {
    console.log('clicked id card');
    const img = document.querySelectorAll('[id=test-card]')[0] as HTMLElement;

    htmlToImage.toCanvas(img).then(function(canvas) {
      // document.body.appendChild(canvas);
      const imgData = canvas.toDataURL();
      const pdf = new jsPDF('l', 'px', [canvas.width, canvas.height]);
      pdf.addImage(imgData, 'JPG', 0, 0, canvas.width, canvas.height);
      pdf.save('id-card.pdf');
    });
  }

  public async provideFeedback(value: number) {
    const feedback: Feedback = {
      flowName: this.message.flowName!,
      responseName: this.message.responseName!,
      feedback: value
    };

    await this.addFeedback(feedback);
    this.returnToNlp('receive_evaluation_from_client');
    this.showFeedback = false;
  }

  mounted() {
    console.log('html: ' + this.$el.children);

    const botResponses = [...this.$el.children];
    botResponses.filter((element) => {
      console.log(element.className);

      element.childNodes.forEach((node) => {
        if (this.index < this.messages.length - 1 && element.innerHTML.indexOf('button') > -1) {
          (node as HTMLInputElement).disabled = true;
        }
      });

      if (element.innerHTML.indexOf('[feedback]') > -1) {
        element.innerHTML = element.innerHTML.replace('[feedback]', '');
        this.showFeedback = this.index === this.messages.length - 1;
      }
    });
  }

  updated() {
    console.log('html: ' + this.$el.children);

    const botResponses = [...this.$el.children];
    botResponses.filter((element) => {
      console.log(element.className);

      if (element.innerHTML.indexOf('[feedback]') > -1) {
        element.innerHTML = element.innerHTML.replace('[feedback]', '');
        this.showFeedback = this.index === this.messages.length - 1;
      }
    });
  }
}
