



























import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NextBestActionEntry } from '@/store/modules/NextBestAction';
import NextBestActionMessage from '@/components/NextBestActionMessage.vue';

const NextBestActionNS = namespace('NextBestAction');

@Component({
  components: { NextBestActionMessage }
})
export default class NextBestAction extends Vue {
  private question = '';

  @NextBestActionNS.State
  messages!: Array<NextBestActionEntry>;

  @NextBestActionNS.Action
  agentQuestion!: (question: string) => void;

  private submitInput() {
    this.agentQuestion(this.question);
    this.question = '';
  }
}
