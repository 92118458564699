import { IntentHeaders } from './types/intent/headers';
import { IntentStartSessionRequest, SendTextRequest, TriggerIntentFlow } from './types/intent/intent-request';
import { IntentResponse, IntentStartSessionResponse } from './types/intent/intent-response';
import { SimpleCache } from './util/SimpleCache';
import { IntentSessionCacheElement } from './util/IntentSessionCacheElement';

import * as _ from 'lodash';

import axios from 'axios';

export class IntentService {
  private sessionCache: SimpleCache<IntentSessionCacheElement>;

  constructor() {
    this.sessionCache = new SimpleCache<IntentSessionCacheElement>(10);
  }

  async createSession(
    headers: IntentHeaders,
    startOrContinuePayload: IntentStartSessionRequest,
    ignorePool?: boolean
  ): Promise<IntentStartSessionResponse> {
    debugger;
    const response = await axios.post<IntentStartSessionResponse>(
      'https://' +
        startOrContinuePayload.cloud +
        '/interact/version/1/account/' +
        headers['Tenant-Id'] +
        '/conversation/domains/' +
        startOrContinuePayload.domainName +
        '/start',
      startOrContinuePayload,
      {
        headers: {
          'Tenant-Id': headers['Tenant-Id'],
          'Application-Key': headers['Application-Key'],
          'Environment-Name': headers['Environment-Name'].toString(),
          'rpa-bot-id': headers['rpa-bot-id']
        },
        responseType: 'json'
      }
    );

    this.sessionCache.put(response.data.sessionId, {
      headers: headers,
      domain: startOrContinuePayload.domainName,
      cloud: startOrContinuePayload.cloud
    });

    return response.data;
  }

  async sendText(sendTextRequest: SendTextRequest): Promise<IntentResponse> {
    const cacheElement = await this.sessionCache.get(sendTextRequest.sessionId);

    if (cacheElement && cacheElement.headers) {
      const response = await axios.post<IntentResponse>(
        'https://' +
          cacheElement.cloud +
          '/interact/version/1/account/' +
          cacheElement.headers['Tenant-Id'] +
          '/conversation/domains/' +
          cacheElement.domain +
          '?sessionId=' +
          sendTextRequest.sessionId,
        {
          text: sendTextRequest.text,
          variables: sendTextRequest.variables
        },
        {
          headers: {
            'Tenant-Id': cacheElement.headers['Tenant-Id'],
            'Application-Key': cacheElement.headers['Application-Key'],
            'Environment-Name': cacheElement.headers['Environment-Name'].toString(),
            'rpa-bot-id': cacheElement.headers['rpa-bot-id']
          },
          responseType: 'json'
        }
      );

      return response.data;
    } else {
      throw 'Session not found in session pool';
    }
  }

  async getBotIdIfPossible(headers: IntentHeaders): Promise<string | undefined> {
    const appSettingsUrl = `https://gointeract.io/interact/appkeydata/settings/${headers['Application-Key']}?includeAppTranslation=false`;
    const settings = await axios.get(appSettingsUrl, {
      headers: headers,
      responseType: 'json'
    });

    if (settings.data.rpaBotRunMode === 'Attended') {
      try {
        const botIdRes = await axios.get('http://127.0.0.1:9002/rpa/bot/id');
        headers['rpa-bot-id'] = botIdRes.data;
        return botIdRes.data;
      } catch (e) {
        console.warn('No bot available', e);
      }
    }

    return undefined;
  }

  public async transformHtml(
    intentResponse: IntentResponse
  ): Promise<
    | {
        channel?: string;
        responseText?: string;
        questionText?: string;
        buttons?: Array<{
          text: string;
          url: string;
        }>;
      }
    | undefined
  > {
    const response = {
      responseText: '',
      questionText: '',
      buttons: new Array<{
        text: string;
        url: string;
      }>()
    };
    console.log('Intent response', intentResponse);
    if (!intentResponse || !intentResponse.html) {
      return;
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(intentResponse.html, 'text/html');

    const buttons = doc.getElementsByTagName('button');

    const texts = doc.getElementsByTagName('p');
    let finalText = '';
    _.map(texts, (text) => {
      finalText += text.innerText;
    });

    response.responseText = finalText;

    if (buttons.length > 0) {
      _.each(buttons, (button) => {
        const buttonUrl = button.attributes.getNamedItem('go-to-url');
        if (buttonUrl != null) {
          response.buttons.push({
            url: buttonUrl ? buttonUrl.value.split('&amp;').join('&') : '',
            text: button.innerText
          });
        } else {
          console.log(
            'button does not have the go-to-url attribute required. Use navigation button in intent response'
          );
        }
      });
    }

    response.questionText = intentResponse?.nlpEngineResponse?.vendorResponse?.queryResult?.queryText
      ? intentResponse?.nlpEngineResponse?.vendorResponse?.queryResult?.queryText
      : '';

    return response;
  }
}
