


















import { Component, Vue, Watch } from 'vue-property-decorator';
import { GuidanceEntry } from '@/store/modules/Guidance';
import { namespace } from 'vuex-class';

const GuidanceNS = namespace('Guidance');

@Component({})
export default class Guidance extends Vue {
  activeTab: number | null = null;

  @GuidanceNS.State
  activatedTab!: number;

  @GuidanceNS.Action
  changeActiveTab!: (tab: number) => void;

  @Watch('activatedTab')
  onActivatedTabChanged(val: number, oldVal: number) {
    this.activeTab = val;
  }

  @Watch('activeTab')
  onActiveTabChanged(val: number, oldVal: number) {
    this.changeActiveTab(val);
  }

  @GuidanceNS.State
  activeScripts!: GuidanceEntry[];

  @GuidanceNS.State
  scripts!: GuidanceEntry[];
}
