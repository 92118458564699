import axios from 'axios';

export interface AuthResponse {
  userId: string;
  tenantId: string;
  roles: Array<string>;
  restrictProductionAccess: boolean;
  mfa: boolean;
  'Access-Token': string;
  'Weak-Password': boolean;
}

export default class LoginService {
  constructor(
    private accountId: string,
    private username: string,
    private password: string,
    private appKey: string,
    private env: string
  ) {}

  async auth(): Promise<AuthResponse> {
    const auth = false;
    try {
      const res = await axios.post<AuthResponse>('https://interact-login-wrapper.jacadademos.com/login', {
        env: this.env,
        username: btoa(this.username),
        password: btoa(this.password),
        accountId: this.accountId,
        'Application-Key': 'loginapplicationkey',
        appKey: this.appKey
      });

      return res.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async getRoles(): Promise<Array<string>> {
    const res = new Array<string>();

    return res;
  }
}
