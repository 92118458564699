import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { KnowledgeArticle } from '@/types';

@Module({ namespaced: true })
export default class SimpleKnowledge extends VuexModule {
  articles: KnowledgeArticle[] = [];

  @Mutation
  add(article: KnowledgeArticle) {
    this.articles.push(article);
    console.log(this.articles.length);
  }

  @Action
  addMany(articles: KnowledgeArticle[]) {
    for (const item of articles) {
      this.context.commit('add', item);
    }
  }

  @Mutation
  reset() {
    this.articles = [];
  }
}
