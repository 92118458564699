
































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { NotificationObj } from '@/store/modules/Messages';
import { namespace } from 'vuex-class';
import eventBus from '@/eventBus';

const messages = namespace('Messages');

@Component({})
export default class NotificationsBar extends Vue {
  @messages.State
  notifications!: Array<NotificationObj>;

  @messages.Action
  private addNotification!: (notification: NotificationObj) => void;

  @messages.Action
  private clearNotifications!: () => void;

  private toggleNotifications() {
    this.showNotifications = !this.showNotifications;

    eventBus.$emit('notificationsBarChanged', this.showNotifications);
  }

  private showNotifications = false;

  private model = null;

  private lastLength = 0;

  @Watch('notifications')
  notificationsChanged(notifications: Array<NotificationObj>) {
    if (notifications.length > this.lastLength) {
      this.showNotifications = true;
    }

    this.lastLength = notifications.length;
    eventBus.$emit('notificationsBarChanged', this.showNotifications);
  }
}
