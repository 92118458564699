export const enum Environment {
  Dev = 'Dev',
  Test = 'Test',
  Prod = 'Prod'
}

export const enum CloudEnvironment {
  US = 'gointeract.io',
  EU = 'eu.gointeract.io',
  INT = 'int.gointeract.io'
}

export const enum NLPEngineType {
  DIALOGFLOW = 'DIALOGFLOW',
  WATSON = 'WATSON',
  OTHER = 'OTHER'
}
