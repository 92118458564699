import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import Config from '@/components/Config.vue';
import Twilio from '@/views/Twilio.vue';
import Login from '@/components/Login.vue';
import Quiq from '@/views/Quiq.vue';
import SelectConfig from '@/components/SelectConfig.vue';
import UAssist from '@/views/UAssist.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/login/:configId',
    name: 'LoginWithConfigId',
    component: Login
  },
  {
    path: '/config/:configId',
    name: 'config',
    component: Config
  },
  {
    path: '/config',
    name: 'selectConfig',
    component: SelectConfig
  },
  {
    path: '/twilio/:side/:configId/',
    name: 'twilioSide',
    component: Twilio
  },
  {
    path: '/uassist/:side/:configId/',
    name: 'uassistSide',
    component: UAssist
  },
  {
    path: '/quiq/:side/:configId/',
    name: 'quiqSide',
    component: Quiq
  }
];

const router = new VueRouter({
  mode: 'history',
  base: window.location.pathname.includes('quiqIndex') ? process.env.BASE_URL + 'quiqIndex' : process.env.BASE_URL,
  routes
});

export default router;
