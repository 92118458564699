import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

export interface GuidanceEntry {
  name: string;
  script: string;
  state?: 'started' | 'finished' | 'added';
}

@Module({ namespaced: true })
export default class Guidance extends VuexModule {
  scripts: GuidanceEntry[] = [];

  activatedTab = 0;

  @Mutation
  add(script: GuidanceEntry) {
    this.scripts.push(script);
  }

  @Mutation
  remove(script: GuidanceEntry) {
    this.scripts = this.scripts.filter((value) => {
      return value.name !== script.name;
    });
  }

  @Mutation
  setActiveTab(tab: number) {
    this.activatedTab = tab;
  }

  @Action
  addAndStart(script: GuidanceEntry) {
    this.context.commit('add', script);
    this.context.commit('setActiveTab', this.scripts.length - 1);
  }

  @Action
  finishAndRemove(script: GuidanceEntry) {
    this.context.commit('remove', script);
  }

  @Action
  changeActiveTab(tab: number) {
    this.context.commit('setActiveTab', tab);
  }
}
