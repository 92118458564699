

















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { InteractPageSection } from '@/types';

@Component({})
export default class RatingsElement extends Vue {
  @Prop({ required: true }) readonly section!: InteractPageSection;

  private selection = 0;

  private updateInputParameters() {
    this.$emit('updateInputParameters', this.section.id, this.selection);
  }

  mounted() {
    this.$emit('updateInputParameters', this.section.id, this.selection);
  }
}
