import { Client, IMessage } from '@stomp/stompjs';
import SockJS from 'sockjs-client';

export class EventService {
  private stompClient!: Client;
  private readonly topicPrefix = '/topic/';
  private config: { tenantId: string; apiKey: string; environment: 'Dev' | 'Test' | 'Prod' };

  constructor(config: { tenantId: string; apiKey: string; environment: 'Dev' | 'Test' | 'Prod' }) {
    console.log('initializing EventService', {
      config: config
    });

    this.config = config;
  }

  async connect(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.stompClient = new Client({
        webSocketFactory() {
          const messagingServer = 'https://gointeract.io/messaging/stomp';
          return new SockJS(messagingServer);
        },
        connectHeaders: this.getHeaders(),
        onConnect: () => {
          resolve(true);
        },
        onDisconnect: (receipt) => {
          console.error('onDisconnect', receipt);
        },
        onStompError: (err) => {
          console.error('onStompError', err);
        },
        onWebSocketError: (receipt) => {
          console.error('onWebSocketError', receipt);
        }
      });

      this.stompClient.activate();
    });
  }

  public subscribe(topic: string, onmessage: (message: IMessage) => void) {
    if (this.stompClient) {
      console.log('subscribe to topic ' + topic);
      return this.stompClient.subscribe(this.getTenantTopic(topic), onmessage, this.getHeaders());
    }
  }

  private getTenantTopic(topic: string) {
    return this.topicPrefix + this.config.tenantId + topic;
  }

  private getHeaders() {
    return {
      'Application-Key': this.config.apiKey,
      'Tenant-Id': this.config.tenantId,
      'Environment-Name': this.config.environment,
      ack: 'client'
    };
  }
}
